<template>
  <div>
    <div class="locale">
      <h2>系统设置</h2>
      <ol>
        <li><router-link to="/">后台</router-link></li>
        <li><router-link to="#">系统</router-link></li>
        <li>系统设置</li>
      </ol>
    </div>


    <a-form-model ref="form" :rules="form.rules" :model="form.data" v-bind="form.layout" @submit="onSubmit" @submit.native.prevent>
    
    <div class="box">
      <div class="box-head">
        <h3>后台IP限制设置</h3>
      </div>
      <div class="box-body">
        <table class="list">
          <tr>
            <th>
              <h4>IP限制</h4>
              <small>是否开启IP白名单，开启后只有白名单里的IP可以访问系统，超级管理员不受此限制！</small>
            </th>
            <td :colspan="2">
              <a-form-model-item prop="staff_coding">
                <a-switch v-model="form.data.ipblock" checked-children="开启" un-checked-children="关闭" />
              </a-form-model-item>
            </td>
          </tr>

          <tr>
            <th>
              <h4>IP白名单 </h4>
              <small>系统访问IP白名单，只有在白名单中的IP或IP段才可访问，当前IP：<span style="color: red;">{{ip}}</span></small>
            </th>
            <td :colspan="2">
              <a-form-model-item prop="staff_coding">
                <a-textarea :auto-size="true" v-model="form.data.iplists" placeholder="IP列表，一行一个，如：58.246.141.221，58.246.141.*" />
              </a-form-model-item>
            </td>
          </tr>
        </table>
      </div>
    </div>

      <div class="submit">
        <a-button type="primary" html-type="submit">提交修改</a-button>
      </div>


    </a-form-model>


  </div>
</template>

<script>
export default {
  name: 'Setting',
  data() {
    return {
      ip: '',
      form: {
        rules: {
          // name: [{ type: 'string', required: true, message: '名称不可为空' }],
        },
        data: {
          name: '', title: '', slogan: '',
          ipblock: false, iplists: ''
        }
      }
    }
  },
  computed: {
    staff () {
      return this.$store.state.staff;
    }
  },
  mounted() {
    this.request();
  },
  methods: {
    request() {
      this.$get('/sys/setting').then(res=>{
        let ajax = res.data;
        if (ajax.code == 0 && ajax.data) {
          ajax.data.setting.iplists = ajax.data.setting.iplists.join('\n');

          for (let key in this.form.data) {
            let val = ajax.data.setting[key];
            if (val !== null) {
              this.form.data[key] = val;
            }
          }

          this.ip = ajax.data.ip;

        }
      })
    },

    onReset() {
      this.$refs.form.resetFields();
    },
    onSubmit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.setting();
        }
      });

    },
    setting (){
      let form = new FormData();
      for (let key in this.form.data) {
        let val = this.form.data[key];
        if (key == 'iplists') {
          if (val) {
            form.append(key, val);
          } else {
            form.append(key, '[]');
          }
        } else {
          form.append(key, val);
        }
      }
      this.$upload('/sys/setting', form).then(res => {
        let ajax = res.data;
        if (ajax.code == 0) {
          setTimeout(()=>{
            this.$router.go(-1);
          }, 500);
        }
      })
    },
  }
}
</script>

<style type="less" scoped>
  th {
    width: 50%;
  }
  th /deep/ h4 {
    margin: 0; padding: 0; color: #555; font-weight: 500;
    font-size: 14px; border-left: 0;
  }
  th /deep/ small {
    display: block; margin-top: 8px;
    color: #999 !important; font-weight: normal;
  }
  td {
    width: 50%;
    /*text-align: right;*/
    vertical-align: middle;
  }
  td /deep/ .ant-form-item {
    margin-bottom: 0;
  }
   .green {
    color:#1890ff;
  }
</style>